@import-normalize;

@import "react-reflex/styles.css";

@import "@vscode/codicons/dist/codicon.css";

@import "xterm/css/xterm.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
}

body .reflex-container.horizontal > .reflex-splitter {
  @apply bg-gray-700 border-0 h-0.5 w-full transition ease-linear duration-100;
}
body .reflex-container.vertical > .reflex-splitter {
  @apply bg-gray-700 border-0 h-full w-0.5 transition ease-linear duration-100;
}

body .reflex-container.horizontal > .reflex-splitter.active,
body .reflex-container.horizontal > .reflex-splitter:hover,
body .reflex-container.vertical > .reflex-splitter.active,
body .reflex-container.vertical > .reflex-splitter:hover {
  @apply border-0 bg-blue-400;
}
